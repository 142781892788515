<template>
  <q-dialog ref="dialog" value persistent @show="initData" @hide="onHide">
    <q-card>
       <q-card-section class="text-center">
          <div>Se desideri aggiungere alla stampa un valore al mq<br /> o delle note compila questi campi</div>
        </q-card-section>

        <q-card-section>
          <div class="row q-col-gutter-md q-mb-md">
            <div class="col column">
              <div>Prezzo al mq</div>
              <q-input
                filled
                v-model="squareMeterPrice"
                min="1"
                max="100000"
                :rules="[
                  val => {
                    const regex = new RegExp('[0-9]+');
                    return regex.test(val)
                  },
                  val => val >= 0 || 'Il valore deve essere maggiore di 0',
                  val => val <= 100000 || 'Il valore massimo consentito è 100000'
                ]"
                lazy-rules
              >
                <template v-slot:prepend>
                  <q-icon name="euro" size="xs" />
                </template>
              </q-input>
            </div>
            <div class="col column">
              <div>Superficie</div>
              <q-input disable filled :value="totalAreaInt">
                <template v-slot:prepend>
                  <small>mq</small>
                </template>
              </q-input>
            </div>
            <div class="col column">
              <div>Valore immobile</div>
              <q-input disable filled :value="value">
                <template v-slot:prepend>
                  <q-icon name="euro" size="xs" />
                </template>
              </q-input>
            </div>
          </div>
          <div class="row">
            <div class="col">
                <q-input
                  label="Note"
                  filled
                  hint="Qui puoi inserire un commento sulla valutazione"
                  type="textarea"
                  v-model="notes"
                />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn outline size="md" color="accent" v-close-popup>{{ $t('generals.close') }}</q-btn>
          <q-btn size="md" color="accent"  @click="saveValue">{{ $t('Salva e continua') }}</q-btn>
        </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ValueCalculatorDialog',
  props: ['project', 'totalArea', 'onHide', 'saveCallback'],
  data () {
    return {
      squareMeterPrice: null,
      notes: ''
    }
  },
  methods: {
    saveValue () {
      this.saveCallback(false, '', {
        square_meter_price: this.squareMeterPrice,
        value_notes: this.notes
      })
      this.$refs.dialog.hide()
    },
    initData () {
      this.squareMeterPrice = this.project.square_meter_price
      this.notes = this.project.value_notes
    }
  },
  computed: {
    totalAreaInt () {
      return this.totalArea.toFixed(0)
    },
    value () {
      if (this.squareMeterPrice >= 0 && this.squareMeterPrice <= 10000) {
        const value = (this.totalArea * this.squareMeterPrice).toFixed(0)

        return new Intl.NumberFormat('it-IT').format(value)
      } else {
        return '-'
      }
    }
  }
}
</script>
