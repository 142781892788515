<template>
  <div class="row full-height">
    <div class="col-sm-2 print-hide relative-position column full-height" v-if="pages.length" ref="sidebar">
      <div class="col-auto">
        <q-input class="q-pa-sm bg-white" :label="$t('app.project.name')" input-class="text-weight-medium text-primary text-h6 q-px-sm" v-model="project.name" ref="title"></q-input>
      </div>
      <div class="col hidden-scrollbar overflow-auto">
        <div v-for="(page,index) in pages" :key="index" class="cursor-pointer" @click="selectedPageIndex = index">
          <div class="text-center text-primary text-weight-medium">{{ $t('app.page', { page: index + 1 }) }}</div>
          <div class="bg-grey-2 q-ma-sm q-pa-sm relative-position">
            <div class="floating-toolbar no-border absolute flex justify-end" style="top: 15px; right:15px">
              <div class="toolbar-button toolbar-button-sm text-accent" @click.prevent="removePage(page)">
                <i class="la la-times"></i>
              </div>
            </div>
            <img :src="page.background_image ? page.background_image : page.background_image_url" class="full-width">
          </div>
          <hr />
        </div>
        <div @click="pickFile" class="flex justify-center bg-grey-2 q-ma-sm add-button squared cursor-pointer items-center relative-position">
          <q-file
            ref="filePicker"
            v-show="false"
            @input="processFile"
          />
          <div class="absolute add-button-content text-center column full-width items-center" style="width: 175px">
            <img src="/images/add.svg">
            <div class="text-dark-gray">{{ $t('app.add_planimetry') }}</div>
          </div>
        </div>
      </div>
      <div class="bg-white full-width flex justify-center q-px-md q-pb-sm col-auto" ref="footer">
        <q-btn size="md" color="accent" class="full-width" @click="checkIfEdited('new_project')">{{ $t('Nuovo progetto') }}</q-btn>
        <q-btn outline size="md" color="accent" class="full-width q-mt-sm" @click="checkIfEdited('projects')">{{ $t('Progetti salvati') }}</q-btn>
      </div>
    </div>
    <div class="full-height" :class="{ 'col-sm-10': pages.length, 'col-sm-12': !pages.length }">
      <div class="full-height flex items-center justify-center" v-if="!pages.length">
        <q-btn flat @click="pickFile">
          <div class="flex column items-center q-pa-md">
            <img src="/images/icons/upload.png" style="width: fit-content; max-width: 100px">
            <h6 class="text-primary q-mb-none">{{ $t('app.upload') }}</h6>
            <p class="text-primary text-caption">{{ $t('app.upload_format') }}</p>
          </div>
        </q-btn>
        <q-file
          ref="filePicker"
          v-show="false"
          @input="processFile"
        />
      </div>
      <div class="full-height full-width page-content relative-position" style="padding-bottom: 48px" v-else-if="selectedPage">
        <div id="canvas-container" class="col-grow full-width full-height">
          <canvas id="canvas-tools"></canvas>
          <div id="scale-dialog" class="tooltip caret-down absolute" v-if="currentScale && showScaleDialog" :style="scalePosition">
            <div class="bg-black text-white text-center text-uppercase">{{ $t('app.toolbar.scale_dialog_header') }}</div>
            <q-card class="q-px-lg q-py-sm text-center text-accent">
              <div class="text-weight-medium text-h6">{{ $t('app.toolbar.scale') }}</div>
              <div class="flex items-center justify-center">
                <q-input
                  style="width: 120px"
                  mask="#,##"
                  fill-mask="0"
                  reverse-fill-mask
                  class="q-mr-sm"
                  maxlength="6"
                  input-class="text-weight-medium text-primary text-h6 q-px-sm"
                  color="accent"
                  dense
                  type="number"
                  step="0.1"
                  @input="val => saveCanvas()"
                  v-model.number="currentScale.value"
                /> m
              </div>
            </q-card>
          </div>
          <div id="distance-dialog" class="tooltip caret-down absolute" v-if="distance && showDistanceDialog" :style="distancePosition">
            <q-card class="q-px-lg q-py-sm text-center text-accent">
              <div class="text-weight-medium text-h6">{{ $t('app.toolbar.distance')}}</div>
              <div class="flex items-center">
                <span class="text-weight-medium text-primary text-h6 q-px-sm">
                  <template v-if="distance.length">{{ distance.length.toFixed(2) }}</template>
                  <template v-else>0</template>
                </span>
                m
              </div>
            </q-card>
          </div>
          <q-dialog ref="saveDialog" style="width: 500px;">
            <q-card>
              <q-card-section>
                <div class="text-h5 text-weight-nedium">{{ $t('Ci sono delle modifiche non salvate') }}</div>
                <div class="text-h6 q-my-md">
                  {{ $t('Se cambi pagina senza salvare queste modifiche verranno perse.') }}
                </div>
                <div class="flex q-pt-lg">
                  <q-btn outline color="default" class="" @click.prevent="$refs.saveDialog.hide()">{{ $t('generals.cancel') }}</q-btn>
                  <q-btn color="warning" class="q-ml-auto" @click.prevent="$router.push({ name: navigatingTo })">{{ $t('Non salvare') }}</q-btn>
                  <q-btn color="accent" class="q-ml-md" @click.prevent="saveProject(false, navigatingTo)">{{ $t('Salva e continua') }}</q-btn>
                </div>
              </q-card-section>
            </q-card>
          </q-dialog>
          <div id="area-dialog" style="width: 900px; position: fixed; left: 100vw">
            <div class="text-h6 text-accent text-center">{{ $t('La superficie commerciale è') }}</div>
            <div class="text-h2 text-weight-medium text-center q-my-lg">
              {{ totalArea.toFixed(2) }} m²
            </div>
            <table class="full-width q-px-xl q-mt-md">
              <tbody>
              <tr class="text-weight-medium" v-for="percentage in filteredPercentages" :key="percentage.value">
                <td><div :style="`width: 15px; height: 15px; background-color:${percentage.bgColor}`"></div></td>
                <td class="full-width vertical-middle">{{ $t('app.percentages.' + percentage.value)}}</td>
                <td class="full-width text-no-wrap text-right"><span class="text-weight-bold text-subtitle1">{{ percentage.area.toFixed(2) }} mq</span> {{ $t('app.calculated_at', { percentage: percentage.value }) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <q-dialog v-model="showingAreaDialog">
            <q-card style="max-width: 900px;">
              <q-btn class="absolute" style="right: 0; z-index: 1" icon="close" flat round dense v-close-popup  />
              <q-card-section class="row justify-center q-pb-none">
                <div class="text-h6 text-accent">{{ $t('La superficie commerciale è') }}</div>
              </q-card-section>
              <q-card-section class="text-primary">
                <div class="text-h2 text-weight-medium text-center">
                  {{ totalArea.toFixed(2) }} m²
                </div>
                <q-separator class="q-mt-sm" />
                <table class="full-width q-px-xl q-mt-md">
                  <tbody>
                    <tr class="text-weight-medium" v-for="percentage in filteredPercentages" :key="percentage.value">
                    <td><div :style="`width: 15px; height: 15px; background-color:${percentage.bgColor}`"></div></td>
                    <td class="vertical-middle"><div style="width:300px">{{ $t('app.percentages.' + percentage.value)}}</div></td>
                    <td class="text-no-wrap text-right"><span class="text-weight-bold text-subtitle1">{{ percentage.area.toFixed(2) }} mq</span></td>
                    <td class="text-no-wrap">{{ $t('app.calculated_at', { percentage: percentage.value }) }}</td>
                    <td class="full-width text-no-wrap text-right"><span class="text-weight-bold text-subtitle1">{{ ((percentage.area / 100) * percentage.value).toFixed(2) }} mq</span></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td class="vertical-middle text-left"><q-separator/></td>
                    <td></td>
                    <td class="text-no-wrap text-right"><q-separator/></td>
                  </tr>
                  <tr class="text-weight-bold">
                    <td></td>
                    <td></td>
                    <td style="min-width:85px" class="vertical-middle text-right text-subtitle1 text-weight-bold">{{ realTotalArea.toFixed(2) }} mq</td>
                    <td></td>
                    <td style="min-width:85px" class="vertical-middle text-right text-subtitle1 text-weight-bold">{{ totalArea.toFixed(2) }} mq</td>
                  </tr>
                  </tbody>
                </table>
              </q-card-section>
              <q-card-section class="row justify-center">
                <q-btn outline color="accent" class="q-mr-md" @click.prevent="printCanvas">{{ $t('app.print_button_text') }}</q-btn>
                <q-btn outline color="accent" class="q-ml-md" @click.prevent="checkIfEdited('new_project')">{{ $t('app.new_button_text') }}</q-btn>
              </q-card-section>
            </q-card>
          </q-dialog>
          <vue-draggable-resizable @dragging="onDragging" :resizable="false" w="auto" h="auto" :x="15" :y="15" :parent="true" class="floating-toolbar print-hide" :class="'position-' + toolbarPosition">
            <div class="q-mb-sm text-center drag-indicator"><q-icon name="more_horiz" size="xs"/></div>
            <div class="toolbar-button" :class="{active: action === 'drag'}" @click.prevent="action = 'drag'">
              <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                {{ $t('app.toolbar.drag_tooltip')}}
              </q-tooltip>
              <i class="ap ap-drag"></i>
              <div>SPOSTA</div>
            </div>

            <div class="row full-width q-mb-sm">
              <div class="col text-center" @click.prevent="zoomIn">
                <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                  {{ $t('app.toolbar.zoom_in_tooltip')}}
                </q-tooltip>
                <i class="ap ap-zoom-in" style="font-size: 16px;"></i>
              </div>
              <div class="col text-center" @click.prevent="zoomOut">
                <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                  {{ $t('app.toolbar.zoom_out_tooltip')}}
                </q-tooltip>
                <i class="ap ap-zoom-out" style="font-size: 16px;"></i>
              </div>
            </div>

            <div class="toolbar-button" :class="{ active: action === 'polygon' }" @click.prevent="action = 'polygon'">
              <div class="ribbon ribbon-bottom-right" v-if="selectedPercentageValue">
                <span class="no-wrap" :style="`background-color:${selectedPercentage.bgColor}; color:${selectedPercentage.textColor};`">{{ selectedPercentageValue }}%</span>
              </div>
              <i class="ap ap-pencil q-mr-sm"></i>
              <div class="sub-toolbar">
                <div v-for="(percentage, index) in percentages" :key="index" class="toolbar-button text-black percentage-options" @click.stop="selectPercentage(percentage.value)">
                  <q-tooltip :content-style="`background-color:${percentage.bgColor}; color:${percentage.textColor}`" :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                    {{ $t('app.toolbar.percentage_tooltip', { percentage: percentage.value })}}
                  </q-tooltip>
                  <div :class="{ active: selectedPercentageValue === percentage.value }" :style="`background-color:${percentage.bgColor}; color:${percentage.textColor}`">{{ percentage.value }}%</div>
                </div>
              </div>
            </div>
            <div class="toolbar-button" :class="{active: action === 'select'}" @click.prevent="action = 'select'">
              <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                {{ $t('app.toolbar.select_tooltip')}}
              </q-tooltip>
              <i style="font-size: 16px" class="ap ap-cursor"></i>
              <div>MODIFICA</div>
            </div>
            <div class="toolbar-button" :class="{active: action === 'ruler'}" @click.prevent="action = 'ruler'">
              <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                {{ $t('app.toolbar.add_scale_tooltip')}}
              </q-tooltip>
              <img v-if="action !== 'ruler'" src="/images/icons/scala.svg" width="25px" height="25px">
              <img v-else src="/images/icons/scala-w.svg" width="25px" height="25px">
              <div style="margin-bottom:4px; line-height: 7px; font-size:8px">IMPOSTA SCALA</div>
            </div>
            <div class="toolbar-button" :class="{active: action === 'distance'}" @click.prevent="action = 'distance'" :disabled="!scaleSet">
              <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                {{ $t('app.toolbar.measure_tooltip')}}
              </q-tooltip>
              <i class="ap ap-width"></i>
              <div style="margin-bottom:4px; line-height: 10px; font-size:8px">MISURA PARETE</div>
            </div>
            <div class="toolbar-button" @click.prevent="showAreaDialog" :disabled="!scaleSet">
              <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                {{ $t('app.toolbar.calculate_area_tooltip')}}
              </q-tooltip>
              <i class="ap ap-calculate"></i>
              <div>CALCOLA</div>
            </div>
            <q-separator class="q-my-sm" />
            <div class="toolbar-button" @click.prevent="action = 'labels'">
              <!-- <img src="/images/icons/labels.svg" width="40px" height="27px"> -->
              <div style="padding: 2px; font-size: 7px">LAYOUT ETICHETTE</div>
              <div class="sub-toolbar">
                <div class="toolbar-button text-button" :class="{ active: selectedLabelsMode === 'visible' }" @click.stop="selectLabelMode('visible')">
                  <div class="label-button">{{ $t('app.toolbar.labels.labels_visible') }}</div>
                </div>
                <div class="toolbar-button text-button" :class="{ active: selectedLabelsMode === 'transparent' }" @click.stop="selectLabelMode('transparent')">
                  <div class="label-button">{{ $t('app.toolbar.labels.labels_transparent') }}</div>
                </div>
                <div class="toolbar-button text-button" :class="{ active: selectedLabelsMode === 'visible_small' }" @click.stop="selectLabelMode('visible_small')">
                  <div class="label-button">{{ $t('app.toolbar.labels.labels_visible_small') }}</div>
                </div>
                <div class="toolbar-button text-button" :class="{ active: selectedLabelsMode === 'transparent_text_only' }" @click.stop="selectLabelMode('transparent_text_only')">
                  <div class="label-button">{{ $t('app.toolbar.labels.labels_transparent_text_only') }}</div>
                </div>
                <div class="toolbar-button text-button" :class="{ active: selectedLabelsMode === 'hidden' }" @click.stop="selectLabelMode('hidden')">
                  <div class="label-button">{{ $t('app.toolbar.labels.labels_hidden') }}</div>
                </div>
                <q-separator></q-separator>
                <div class="toolbar-button text-button" :class="{ active: selectedPercentageMode === 'visible' }" @click.stop="selectPercentageMode('visible')">
                  <div class="label-button">Con percentuale</div>
                </div>
                <div class="toolbar-button text-button" :class="{ active: selectedPercentageMode === 'hidden' }" @click.stop="selectPercentageMode('hidden')">
                  <div class="label-button">Senza percentuale</div>
                </div>
              </div>
            </div>
            <div class="toolbar-button" @click.prevent="openValueCalculator">
              <q-tooltip :anchor="'center ' + (toolbarPosition === 'right' ? 'left' : 'right')" :self="'center ' + toolbarPosition">
                {{ $t('app.toolbar.value_tooltip')}}
              </q-tooltip>
              <img src="/images/icons/value.svg" width="25px" height="25px">
              <div style="margin-bottom:4px; line-height: 8px; font-size:8px">NOTE SU STAMPA</div>
            </div>
          </vue-draggable-resizable>
        </div>
        <div class="bottom-bar text-accent no-wrap bg-white absolute-bottom full-width flex items-center q-py-sm q-px-md text-weight-medium text-h6">
          <q-btn no-wrap class="bg-grey-8 text-white q-mr-md" size="md" color="accent" @click.prevent="saveProject(false)">
            <q-tooltip :anchor="'top middle'" :self="'center middle'" :offset="[0, 20]">
                  {{ $t('app.toolbar.save')}}
            </q-tooltip>
            <i style="font-size: 16px;margin-top:2px" class="q-mr-sm ap ap-save"></i>
            <span>SALVA</span>
          </q-btn>
          <q-btn no-wrap class="bg-grey-8 text-white q-mr-md" size="md" color="accent" @click.prevent="printCanvas">
            <q-tooltip :anchor="'top middle'" :self="'center middle'" :offset="[0, 20]">
              {{ $t('app.toolbar.print_result_tooltip')}}
            </q-tooltip>
            <i style="font-size: 16px;margin-top:2px" class="q-mr-sm ap ap-printer"></i>
            <span>STAMPA</span>
          </q-btn>
          <q-btn v-if="polygonVariables.pointArray.length" class="q-mr-md bg-negative text-white" flat size="md" color="accent" @click.prevent="cancelAction">
            <q-tooltip :anchor="'top middle'" :self="'center middle'" :offset="[0, 20]">
                {{ $t('app.toolbar.cancel_action_tooltip')}}
            </q-tooltip>
            <span>ANNULLA</span>
          </q-btn>
          <i class="ap ap-info fa-lg q-mr-sm"></i>
          <span v-if="polygonVariables.pointArray.length" style="font-size: 18px;" class="text-weight-bold text-negative ellipsis">{{ $t('app.press_esc_to_cancel')}}</span>
          <span v-else-if="!currentScale">{{ $t('app.add_scale_info')}}</span>
          <span v-else-if="!currentScale.value">{{ $t('app.add_scale_value_info')}}</span>
          <span v-else>{{ $t('app.calculate_info')}}</span>
        </div>
      </div>

      <value-calculator-dialog v-if="showValueCalculatorDialog" :project="project" :total-area="totalArea" :on-hide="onValueCalculatorDialogHide" :save-callback="saveProject" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { QFile, QTooltip, QDialog, Loading } from 'quasar'
import User from 'src/store/models/User'
import Page from 'src/store/models/Page'
import ValueCalculatorDialog from 'src/components/ValueCalculatorDialog'

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)
import { fabric } from 'fabric'
import area from 'area-polygon'
import { debounce, find, each, /* reverse, tail, */first, filter, cloneDeep } from 'lodash'
import Project from 'src/store/models/Project'

function initialState () {
  return {
    showValueCalculatorDialog: false,
    edited: false,
    totalArea: 0,
    canvas: null,
    action: 'polygon',
    selectedPercentageValue: 100,
    selectedLabelsMode: 'visible',
    selectedPercentageMode: 'visible',
    toolbarPosition: 'left',
    showScaleDialog: false,
    showDistanceDialog: false,
    showingAreaDialog: false,
    scalePosition: null,
    distancePosition: null,
    prototypeFabric: {},
    distance: null,
    percentages: [
      {
        value: 100,
        area: 0,
        bgColor: '#FA839A',
        textColor: '#000000'
        // Area rossa
      },
      {
        value: 80,
        area: 0,
        bgColor: '#66101F',
        textColor: '#FFFFFF'
        // Area bordeaux
      },
      {
        value: 75,
        area: 0,
        bgColor: '#8D3B72',
        textColor: '#FFFFFF'
        // Area violetto
      },
      {
        value: 60,
        area: 0,
        bgColor: '#0A2463',
        textColor: '#FFFFFF'
        // Area blu scuro
      },
      {
        value: 50,
        area: 0,
        bgColor: '#7DA5DE',
        textColor: '#000000'
        // Area blu
      },
      {
        value: 40,
        area: 0,
        bgColor: '#285943',
        textColor: '#FFFFFF'
        // Area verde
      },
      {
        value: 33,
        area: 0,
        bgColor: '#F2DE8A',
        textColor: '#000000'
        // Area gialla
      },
      {
        value: 30,
        area: 0,
        bgColor: '#FAA916',
        textColor: '#000000'
        // Area giallo scuro
      },
      {
        value: 25,
        area: 0,
        bgColor: '#8CE6E6',
        textColor: '#000000'
        // Area azzurra
      },
      {
        value: 20,
        area: 0,
        bgColor: '#E0C9DF',
        textColor: '#000000'
        // Area lilla
      },
      {
        value: 15,
        area: 0,
        bgColor: '#C3A29E',
        textColor: '#000000'
        // Area beige
      },
      {
        value: 10,
        area: 0,
        bgColor: '#92DDB9',
        textColor: '#000000'
        // Area verde scuro
      },
      {
        value: 5,
        area: 0,
        bgColor: '#B1F6B1',
        textColor: '#000000'
        // Area verde chiaro
      },
      {
        value: 2,
        area: 0,
        bgColor: '#FCB97D',
        textColor: '#000000'
        // Area arancio
      }
    ],
    polygonVariables: {
      polygonMode: true,
      pointArray: [],
      lineArray: [],
      activeLine: null,
      activeShape: null
    },
    isDragging: false,
    isDrawing: false,
    lastPos: {
      x: null,
      y: null
    },
    project: {
      name: ''
    },
    pages: [],
    selectedPageIndex: 0,
    loading: false,
    debouncedSaveCanvas: null
  }
}

export default {
  name: 'AreaPlanApp',

  components: {
    QFile,
    QTooltip,
    QDialog,
    VueDraggableResizable,
    ValueCalculatorDialog
  },

  data () {
    return initialState()
  },

  watch: {
    action: function (val, oldVal) {
      if (oldVal === 'polygon' && this.polygonVariables.pointArray.length) {
        this.prototypeFabric.polygon.generatePolygon(this.polygonVariables.pointArray)
      }

      this.canvas.selection = val === 'select'
      switch (this.action) {
        case 'select':
          this.setObjectsEditable(true)
          this.setObjectsSelectable(true)
          break
        case 'drag':
        case 'ruler':
        case 'distance':
          this.setObjectsEditable(false)
          this.setObjectsSelectable(false)
          this.canvas.discardActiveObject().renderAll()
          break
        default:
          this.setObjectsSelectable(true)
      }
      if (this.action === 'ruler' && this.currentScale) {
        this.currentScale.selectable = true
      }
    },
    selectedLabelsMode: {
      immediate: true,
      handler: function (val) {
        if (this.canvas) {
          if (val) {
            this.canvas.selectedLabelsMode = val
            this.updateAllInnerLabels()
          } else {
            this.selectedLabelsMode = 'visible'
          }
        }
      }
    },
    selectedPercentageMode: {
      immediate: true,
      handler: function (val) {
        if (this.canvas) {
          if (val) {
            this.canvas.selectedPercentageMode = val
            this.updateAllInnerLabels()
          } else {
            this.selectedPercentageMode = 'visible'
          }
        }
      }
    },
    scaleSet (val) {
      if (val && this.canvas) {
        this.updateAllInnerLabels()
      }
    },
    projectEntity: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.setProject(val)
          this.initFabric()
        }
      }
    },
    selectedPage (val, oldVal) {
      if (this.canvas) {
        this.saveCanvas(oldVal)
        this.disposeCanvasEvents()
        this.canvas.dispose()
      }
      this.resetData()
      this.$nextTick(() => {
        this.loading = true
        this.initCanvas()
        this.canvas.loadFromJSON(val.data, () => {
          // resize canvas to stored size
          // if (val.data.height && val.data.width) {
          //   const canvasContainer = document.getElementById('canvas-container')

          //   this.canvas.setWidth(val.data.width > canvasContainer.offsetWidth ? val.data.width : canvasContainer.offsetWidth)
          //   this.canvas.setHeight(val.data.height > canvasContainer.offsetHeight ? val.data.height : canvasContainer.offsetHeight)
          // }
          // this.canvas.requestRenderAll()

          // set current scale
          const scale = find(this.canvas.getObjects(), o => {
            return o['object-type'] === 'scale'
          })

          each(this.canvas.getObjects(), o => {
            o.selectable = this.action !== 'drag'
            if (['distance', 'inner-label'].includes(o['object-type'])) {
              this.canvas.remove(o)
            }
            switch (o.type) {
              case 'circle':
                this.canvas.remove(o)
                break
              case 'polygon':
                if (o.points && !area(o.points)) {
                  this.canvas.remove(o)
                } else {
                  o.drawInnerLabel()
                }
                break
            }
          })
          this.loading = false
          this.currentScale = scale || null
          this.selectedLabelsMode = this.canvas.selectedLabelsMode
          this.selectedPercentageMode = this.canvas.selectedPercentageMode
          if (this.action === 'select') {
            this.setObjectsEditable(true)
            this.setObjectsSelectable(true)
          }
        })

        this.canvas.set('backgroundColor', '#eee')
      })
    }
  },

  computed: {
    me () {
      return User.me()
    },
    scaleSet () {
      return this.currentScale && this.currentScale.value
    },
    currentScale: {
      get () {
        return this.selectedPage ? this.selectedPage.scale : null
      },
      set (val) {
        Vue.set(this.selectedPage, 'scale', val)
      }
    },
    projectEntity () {
      return Project.query().with('pages').find(this.$route.params.id)
    },
    selectedPage () {
      if (this.pages[this.selectedPageIndex]) {
        return this.pages[this.selectedPageIndex]
      } else {
        return first(this.pages)
      }
    },
    selectedPercentage () {
      return find(this.percentages, { value: this.selectedPercentageValue })
    },
    filteredPercentages () {
      return filter(this.percentages, (p) => {
        return p.area > 0
      })
    },
    realTotalArea () {
      const percentages = this.filteredPercentages
      let totalArea = 0
      for (const percentage of percentages) {
        totalArea += percentage.area
      }
      return totalArea
    }
  },

  methods: {
    setProject () {
      this.project = cloneDeep(this.projectEntity)
      this.pages = cloneDeep(this.projectEntity.pages).map(p => {
        const canvas = JSON.parse(p.canvas)
        const data = {
          id: p.id,
          page_index: p.page_index,
          data: canvas,
          background_image: canvas.backgroundImage ? canvas.backgroundImage.src : null,
          background_image_url: p.background_image_url,
          canvas_image: p.canvas_image ? p.canvas_image : null,
          scale: find(canvas.objects, { 'object-type': 'scale' })
        }
        return data
      })
    },
    setEdited (edited = true) {
      this.edited = !!edited
    },
    checkIfEdited (route = '') {
      if (this.edited) {
        if (route) {
          this.navigatingTo = route
        }
        this.$refs.saveDialog.show()
      } else {
        this.$router.push({ name: route })
      }
    },
    openValueCalculator () {
      this.calculateArea()
      this.showValueCalculatorDialog = true
    },
    onValueCalculatorDialogHide () {
      this.showValueCalculatorDialog = false
    },
    getProject (projectId) {
      Loading.show()
      Project.api().get(`/api/companies/${this.me.company_id}/projects/${projectId}`)
        .finally(() => {
          Loading.hide()
        })
    },
    saveProject (printing = false, redirectTo = '', additionalData = {}) {
      if (this.canvas) {
        this.saveCanvas()
      }
      if (this.project.id) {
        // Create form data
        const formData = new FormData()
        formData.append('name', this.project.name)
        this.pages.forEach((page, index) => {
          if (page.id) {
            formData.append(`pages[${index}][id]`, page.id)
          }
          if (page.background_image) {
            formData.append(`pages[${index}][background_image]`, page.background_image)
          }
          formData.append(`pages[${index}][page_index]`, index)
          formData.append(`pages[${index}][canvas]`, JSON.stringify(page.data))
          if (page.canvas_image) {
            formData.append(`pages[${index}][canvas_image]`, page.canvas_image)
          }
        })

        Object.keys(additionalData).forEach(function (key) {
          formData.append(key, additionalData[key])
        })
        Loading.show()
        return Project.api().post(`/api/companies/${this.me.company_id}/projects/${this.project.id}`,
          formData
        )
          .then(({ response }) => {
            // Remove all deleted pages from the store
            Page.delete((page) => {
              return page.project_id === this.project.id && !response.data.pages.map(p => p.id).includes(page.id)
            })
            this.$q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check',
              message: this.$t('generals.project_updated'),
              position: 'top'
            })
            this.setEdited(false)
            if (redirectTo) {
              this.$router.push({ name: redirectTo })
            }
          })
          .finally(() => {
            Loading.hide()
          })
      } else {
        this.$q.dialog({
          title: printing ? this.$t('Prima di stampare è necessario salvare il progetto') : this.$t('Salva'),
          message: this.$t('Inserisci un nome per il progetto'),
          prompt: {
            model: this.project.name,
            type: 'text' // optional
          },
          cancel: true,
          persistent: true
        }).onOk(name => {
          // Create form data
          const formData = new FormData()
          formData.append('name', name)
          this.pages.forEach((page, index) => {
            formData.append(`pages[${index}][page_index]`, index)
            formData.append(`pages[${index}][canvas]`, JSON.stringify(page.data))
            if (page.canvas_image) {
              formData.append(`pages[${index}][canvas_image]`, page.canvas_image)
            }
            if (page.background_image) {
              formData.append(`pages[${index}][background_image]`, page.background_image)
            }
          })
          Loading.show()
          Project.api().post(`/api/companies/${this.me.company_id}/projects`,
            formData
          )
            .then(({ response }) => {
              this.$router.push({ name: 'project_edit', params: { id: response.data.id } }, () => {
                this.setProject()
                this.$q.notify({
                  color: 'positive',
                  textColor: 'white',
                  icon: 'check',
                  message: this.$t('generals.project_created'),
                  position: 'top'
                })
                this.setEdited(false)
                if (printing) {
                  this.printCanvas()
                }
                if (redirectTo) {
                  this.$router.push({ name: redirectTo })
                }
              })
            })
            .finally(() => {
              Loading.hide()
            })
        }).onCancel(() => {
          // console.log('>>>> Cancel')
        }).onDismiss(() => {
          // console.log('I am triggered on both OK and Cancel')
        })
      }
    },
    deleteProject () {
      this.$q.dialog({
        title: this.$t('Conferma eliminazione'),
        message: this.$t('Sei sicuro di voler cancellare questo progetto?'),
        color: 'negative',
        ok: this.$t('Elimina'),
        cancel: true
      }).onOk(() => {
        this.$q.loading.show()
        Project.find(this.project.id).$delete()
          .then(() => {
            this.$q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check',
              message: this.$t('Progetto eliminato con successo'),
              position: 'top'
            })
            this.$router.push({ name: 'projects' })
          })
          .finally(() => {
            this.$q.loading.hide()
          })
      })
    },
    resetDataToInitialState () {
      Object.assign(this.$data, initialState())
      this.initFabric()
    },
    resetData () {
      this.showDistanceDialog = false
      this.showScaleDialog = false
    },
    selectPercentage (percentage) {
      this.action = 'polygon'
      this.selectedPercentageValue = percentage
    },
    selectLabelMode (mode) {
      this.selectedLabelsMode = mode
    },
    selectPercentageMode (mode) {
      this.selectedPercentageMode = mode
    },
    getScalePosition () {
      var point1 = this.currentScale.aCoords.br, point2 = this.currentScale.aCoords.tl
      var dx = point1.x + point2.x, dy = point1.y + point2.y
      let dialog = null

      this.$nextTick(() => {
        dialog = document.getElementById('scale-dialog')
        if (dialog) {
          var offset = window.getComputedStyle(dialog, ':before').height
          dx = (dx - dialog.offsetWidth) / 2
          dy = dy / 2 - dialog.offsetHeight - parseInt(offset)
        }
        this.scalePosition = {
          left: dx + 'px',
          top: dy + 'px'
        }
      })
    },
    getDistancePosition () {
      const line = this.distance.getObjects().filter(l => l.scale === 'main')[0]
      let dx = line.x2 + line.x1, dy = line.y2 + line.y1
      let dialog = null
      this.$nextTick(() => {
        dialog = document.getElementById('distance-dialog')
        if (dialog) {
          var offset = window.getComputedStyle(dialog, ':before').height
          dx = (dx - dialog.offsetWidth) / 2
          dy = dy / 2 - dialog.offsetHeight - parseInt(offset)
        }

        this.distancePosition = {
          left: dx + 'px',
          top: dy + 'px'
        }
      })
    },
    onDragging (left, top) {
      this.toolbarPosition = left > this.canvas.getWidth() / 2 ? 'right' : 'left'
    },
    printCanvas () {
      if (!this.project.id) {
        this.saveProject(true)
      }
      const routeData = this.$router.resolve({ name: 'project_print', target: '_blank', params: { id: this.projectEntity.id } })
      window.open(routeData.href, '_blank')
    },
    removePage (page) {
      this.pages.splice(this.pages.indexOf(page), 1)
    },
    pickFile (e) {
      this.$refs.filePicker.pickFiles(e)
    },
    processFile (file) {
      //  TODO: Filter file size or resize images
      const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png']

      if (file && acceptedImageTypes.includes(file.type)) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.pages.push({
            background_image: reader.result,
            scale: null,
            canvas_image: null,
            data: []
          })

          this.selectedPageIndex = this.pages.length - 1
        }
      } else if (file && file.type === 'application/pdf') {
        this.$q.loading.show()
        const formData = new FormData()
        formData.append('file', file)
        Vue.prototype.$axios.post('/api/convert-pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(r => {
          this.$q.loading.hide()
          each(r.data, base64Image => this.pages.push({
            background_image: base64Image,
            scale: null,
            canvas_image: null,
            data: []
          }))
        }).catch(e => {
          this.$q.loading.hide()
        })
      } else {
        this.$q.notify({
          type: 'negative',
          message: this.$t('app.invalid_uploaded_file')
        })
      }
    },
    updateAllInnerLabels () {
      each(filter(this.canvas.getObjects(), { type: 'polygon' }), obj => {
        obj.updateInnerLabel(this.canvas.selectedLabelsMode)
      })
      setTimeout(() => {
        this.debouncedSaveCanvas()
      }, 500)
    },
    calculateLineLength: (line) => {
      let scaleX = line.scaleX, scaleY = line.scaleY
      if (line.group) {
        scaleX *= line.group.scaleX
        scaleY *= line.group.scaleY
      }
      return Math.sqrt(((line.x2 - line.x1) * scaleX) ** 2 + ((line.y2 - line.y1) * scaleY) ** 2)
    },
    getObjects (obj) {
      if (obj.objects) {
        return obj.objects
      } else if (obj.getObjects) {
        return obj.getObjects()
      } else {
        return []
      }
    },
    setObjectsSelectable (val) {
      each(this.canvas.getObjects(), (object) => {
        object.selectable = val
      })
    },
    setObjectsEditable (val) {
      each(this.canvas.getObjects(), (object) => {
        if (object.type === 'polygon' && !!object.edit !== val) {
          this.prototypeFabric.polygon.toggleEditPolygon(null, object)
        }
      })
      this.canvas.discardActiveObject()
    },
    showAreaDialog () {
      this.calculateArea()
      this.showingAreaDialog = true
    },
    calculateArea () {
      let area = 0
      each(this.percentages, p => {
        p.area = 0
      })
      each(this.pages, page => {
        if (page.data && page.data.objects && Array.isArray(page.data.objects)) {
          each(page.data.objects, object => {
            let partialArea = 0
            if (object.type === 'polygon') {
              partialArea = this.prototypeFabric.polygon.getPolygonArea(object) * object.scaleX * object.scaleY
              let scale = 1
              if (page.scale) {
                const scaleLength = this.calculateLineLength(first(this.getObjects(page.scale)))
                scale = parseFloat(page.scale.value) / scaleLength
                partialArea *= scale ** 2

                const percentage = find(this.percentages, { value: object.percentage })
                if (percentage) {
                  percentage.area += partialArea
                }
                area += partialArea * (object.percentage / 100)
              }
            }
          })
        }
        this.totalArea = area
      })
    },
    createScale () {
      var pointer = this.canvas.getPointer()
      var points = [pointer.x, pointer.y, pointer.x, pointer.y]

      // Main line
      var line = new fabric.Line(points, {
        strokeWidth: 9,
        fill: 'black',
        stroke: 'black',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        objectCaching: true,
        scale: 'border'
      })
      var line2 = new fabric.Line(points, {
        strokeWidth: 7,
        fill: '#eee',
        stroke: '#eee',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        objectCaching: true,
        scale: 'main'
      })
      var line3 = new fabric.Line(points, {
        strokeWidth: 7,
        fill: 'black',
        stroke: 'black',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        objectCaching: true,
        scale: 'dashed'
      })

      // first side
      var line4 = new fabric.Line(points, {
        strokeWidth: 1,
        fill: '#000',
        stroke: '#000',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        objectCaching: true,
        scale: 'side1'
      })
      // second side
      var line5 = new fabric.Line(points, {
        strokeWidth: 1,
        fill: '#000',
        stroke: '#000',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: true,
        objectCaching: true,
        scale: 'side2'
      })

      const group = new fabric.Group()
      group.addWithUpdate(line)
      group.addWithUpdate(line2)
      group.addWithUpdate(line3)
      group.addWithUpdate(line4)
      group.addWithUpdate(line5)
      return group
    },
    cancelAction () {
      if (this.action === 'polygon') {
        if (this.polygonVariables.activeShape) {
          if (this.polygonVariables.activeShape.innerLabel) {
            this.canvas.remove(this.polygonVariables.activeShape.innerLabel)
          }
          this.canvas.remove(this.polygonVariables.activeShape)
        }
        each(this.polygonVariables.pointArray, point => {
          this.canvas.remove(point)
        })
        this.prototypeFabric.polygon.drawPolygon()
      }
    },
    onMouseWheel (opt) {
      var evt = opt.e
      var delta = evt.deltaY
      var zoom = this.canvas.getZoom()
      zoom *= 0.999 ** delta
      if (zoom > 8) zoom = 8
      if (zoom < 0.3) zoom = 0.3

      this.zoomToPoint({ x: evt.offsetX, y: evt.offsetY }, zoom, 'canvas-container')

      evt.preventDefault()
      evt.stopPropagation()
    },
    onMouseDown (opt) {
      const evt = opt.e
      this.showDistanceDialog = false
      if (opt.target && opt.target === this.currentScale) {
        this.showScaleDialog = !this.showScaleDialog
        this.getScalePosition()
      } else {
        this.showScaleDialog = false
      }
      if (this.distance) {
        this.canvas.remove(this.distance)
        this.distance = null
      }
      if (evt.altKey === true) {
        this.isDragging = true
        this.selection = false
        this.lastPos = {
          x: evt.clientX,
          y: evt.clientY
        }
      } else {
        switch (this.action) {
          case 'polygon':
            if (this.polygonVariables.pointArray.length === 0) {
              this.prototypeFabric.polygon.drawPolygon()
            }
            if (
              opt.target &&
              this.polygonVariables.pointArray[0] &&
              opt.target.id === this.polygonVariables.pointArray[0].id &&
              !this.prototypeFabric.polygon.checkIntersections(this.canvas.getPointer(), this.polygonVariables.lineArray)
            ) {
              this.prototypeFabric.polygon.generatePolygon(this.polygonVariables.pointArray)
              this.setObjectsSelectable(true)
            } else {
              // if (!opt.target || (opt.target && this.polygonVariables.pointArray[0])) {
              this.setObjectsSelectable(false)
              this.prototypeFabric.polygon.addPoint(opt)
            }
            break
          case 'drag':
            this.isDragging = true
            this.lastPos = {
              x: evt.clientX,
              y: evt.clientY
            }
            break
          case 'ruler':
            // if (!opt.target) {
            if (this.currentScale) {
              // TODO: alert for removing currentScale
              // this.canvas.remove(this.currentScale)
              // this.currentScale = null
              // this.canvas.requestRenderAll()
            } else {
              this.isDrawing = true
              this.currentScale = this.createScale()
              this.selectedPage.scale.set('object-type', 'scale')
              this.canvas.add(this.currentScale)
            }
            // }
            break
          case 'distance':
            this.isDrawing = true
            var pointer = this.canvas.getPointer()
            var points = [pointer.x, pointer.y, pointer.x, pointer.y]

            // Main line
            var line = new fabric.Line(points, {
              strokeWidth: 3,
              fill: 'red',
              stroke: 'red',
              originX: 'center',
              originY: 'center',
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: true,
              objectCaching: true,
              scale: 'main'
            })
            // Second line
            var line2 = new fabric.Line(points, {
              strokeWidth: 1,
              fill: 'red',
              stroke: 'red',
              originX: 'center',
              originY: 'center',
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: true,
              objectCaching: true,
              scale: 'side1'
            })
            // Third line
            var line3 = new fabric.Line(points, {
              strokeWidth: 1,
              fill: 'red',
              stroke: 'red',
              originX: 'center',
              originY: 'center',
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: true,
              objectCaching: true,
              scale: 'side2'
            })

            var group = new fabric.Group()
            group.addWithUpdate(line)
            group.addWithUpdate(line2)
            group.addWithUpdate(line3)
            this.distance = group
            this.distance.set('object-type', 'distance')
            this.canvas.add(this.distance)

            break
        }
      }
    },
    onDoubleClick (opt) {
      if (this.action === 'polygon' &&
        // opt.target &&
        this.polygonVariables.pointArray.length >= 4 &&
        !this.prototypeFabric.polygon.checkIntersections(this.canvas.getPointer(), this.polygonVariables.lineArray)
      ) {
        // Remove last clicked point
        const lastPoint = this.polygonVariables.pointArray.pop()
        this.canvas.remove(lastPoint)
        const lastLine = this.polygonVariables.lineArray.pop()
        this.canvas.remove(lastLine)
        this.canvas.renderAll()
        // Generate the polygon
        this.prototypeFabric.polygon.generatePolygon(this.polygonVariables.pointArray)
        this.setObjectsSelectable(true)
        this.saveCanvas()
        this.updateAllInnerLabels()
      }
    },
    onSelectionCreated (opt) {
      if (opt.selected) {
        opt.selected.forEach(object => {
          if (object.type === 'polygon') {
            object.removeInnerLabel()
          }
        })
      }
    },
    onSelectionCleared (opt) {
      if (opt.deselected) {
        opt.deselected.forEach(object => {
          if (object.type === 'polygon') {
            object.drawInnerLabel()
          }
        })
      }
    },
    onSelectionUpdated (opt) {
      this.onSelectionCreated(opt)
      this.onSelectionCleared(opt)
    },
    onMouseMove (opt) {
      const evt = opt.e
      const pointer = this.canvas.getPointer(evt)

      if (this.isDragging || evt.which === 3) {
        var vpt = this.canvas.viewportTransform
        vpt[4] += evt.clientX - this.lastPos.x
        vpt[5] += evt.clientY - this.lastPos.y
        // const zoom = this.canvas.getZoom()
        // const canvasContainer = document.getElementById('canvas-container')
        // if (vpt[4] >= 0) {
        //   vpt[4] = 0
        // } else if (vpt[4] < (Math.abs(this.canvas.getWidth() - canvasContainer.offsetWidth * zoom)) * -1) {
        //   vpt[4] = Math.abs(this.canvas.getWidth() - canvasContainer.offsetWidth * zoom) * -1
        // }
        // if (vpt[5] >= 0) {
        //   vpt[5] = 0
        // } else if (vpt[5] < (Math.abs(this.canvas.getHeight() - canvasContainer.offsetHeight * zoom)) * -1) {
        //   vpt[5] = Math.abs(this.canvas.getHeight() - canvasContainer.offsetHeight * zoom) * -1
        // }
        this.canvas.requestRenderAll()
        this.lastPos = {
          x: evt.clientX,
          y: evt.clientY
        }
      } else {
        switch (this.action) {
          case 'polygon':
            if (this.polygonVariables.activeLine && this.polygonVariables.activeLine.class === 'line') {
              this.polygonVariables.activeLine.set({ x2: pointer.x, y2: pointer.y })

              const points = this.polygonVariables.activeShape.get('points')
              points[this.polygonVariables.pointArray.length] = {
                x: pointer.x,
                y: pointer.y
              }
              this.polygonVariables.activeShape.set({
                points: points
              })
              this.polygonVariables.activeShape.updateInnerLabel()
              this.canvas.requestRenderAll()
            }
            break
          case 'ruler':
            if (this.currentScale && this.isDrawing) {
              const l = 15
              const lines = this.currentScale.getObjects()
              const mainLine = lines.filter(l => l.scale === 'main')[0]
              let x1 = mainLine.x1, y1 = mainLine.y1
              let x2 = pointer.x, y2 = pointer.y
              var m = (y1 - y2) / (x1 - x2)
              var alpha = Math.atan(m)
              each(lines, object => {
                this.currentScale.removeWithUpdate(object)
                switch (object.scale) {
                  case 'dashed':
                    object.set({ x2: pointer.x, y2: pointer.y })
                    var lineLength = this.calculateLineLength(object)
                    this.currentScale.set('length', lineLength)
                    object.set('strokeDashArray', [lineLength / 9, lineLength / 9])
                    break
                  case 'side1':
                    x1 = mainLine.x1 - Math.sin(alpha) * l
                    y1 = mainLine.y1 + Math.cos(alpha) * l
                    x2 = mainLine.x1 + Math.sin(alpha) * l
                    y2 = mainLine.y1 - Math.cos(alpha) * l
                    object.set({ x1, x2, y1, y2 })
                    break
                  case 'side2':
                    x1 = mainLine.x2 - Math.sin(alpha) * l
                    y1 = mainLine.y2 + Math.cos(alpha) * l
                    x2 = mainLine.x2 + Math.sin(alpha) * l
                    y2 = mainLine.y2 - Math.cos(alpha) * l
                    object.set({ x1, x2, y1, y2 })
                    break
                  default:
                    object.set({ x2: pointer.x, y2: pointer.y })
                    break
                }
                this.currentScale.addWithUpdate(object)
              })
              this.canvas.requestRenderAll()
            }

            break

          case 'distance':
            if (this.distance && this.isDrawing) {
              const lines = this.distance.getObjects()
              const mainLine = lines.filter(l => l.scale === 'main')[0]
              const side1 = lines.filter(l => l.scale === 'side1')[0]
              const side2 = lines.filter(l => l.scale === 'side2')[0]

              let x1 = mainLine.x1, y1 = mainLine.y1
              let x2 = pointer.x, y2 = pointer.y

              const m = (y1 - y2) / (x1 - x2)
              const alpha = Math.atan(m)
              const l = 8
              this.distance.removeWithUpdate(mainLine)
              mainLine.set({ x2, y2 })
              this.distance.addWithUpdate(mainLine)

              x1 = mainLine.x1 - Math.sin(alpha) * l
              y1 = mainLine.y1 + Math.cos(alpha) * l
              x2 = mainLine.x1 + Math.sin(alpha) * l
              y2 = mainLine.y1 - Math.cos(alpha) * l
              this.distance.removeWithUpdate(side1)
              side1.set({ x1, x2, y1, y2 })
              this.distance.addWithUpdate(side1)

              x1 = mainLine.x2 - Math.sin(alpha) * l
              y1 = mainLine.y2 + Math.cos(alpha) * l
              x2 = mainLine.x2 + Math.sin(alpha) * l
              y2 = mainLine.y2 - Math.cos(alpha) * l
              this.distance.removeWithUpdate(side2)
              side2.set({ x1, x2, y1, y2 })
              this.distance.addWithUpdate(side2)

              var length = this.calculateLineLength(mainLine)
              var scaleLength = this.calculateLineLength(first(this.currentScale.getObjects()))
              length *= (parseFloat(this.currentScale.value) / scaleLength)
              this.distance.set('length', length)
              this.canvas.requestRenderAll()
            }

            break
          // case 'distance':
          //   if (this.isDrawing) {
          //     // this.canvas.remove(this.distance.text)
          //     this.distance.set({ x2: pointer.x, y2: pointer.y })
          //     var length = this.calculateLineLength(this.distance)
          //     var scaleLength = this.calculateLineLength(first(this.currentScale.getObjects()))
          //     length *= (parseFloat(this.currentScale.value) / scaleLength)
          //     this.distance.set('length', length)
          //     this.canvas.requestRenderAll()
          //   }
          //   break
        }
      }
    },
    onMouseUp (opt) {
      // const evt = opt.e
      if (this.isDragging) {
        this.isDragging = false
        this.canvas.setViewportTransform(this.canvas.viewportTransform)
      }

      switch (this.action) {
        case 'polygon':
          break
        // case 'drag':
        //   this.isDragging = false
        //   this.canvas.setViewportTransform(this.canvas.viewportTransform)
        //   break
        case 'ruler':
          if (this.isDrawing) {
            var lineLength = this.calculateLineLength(first(this.currentScale.getObjects()))
            this.currentScale.set('length', lineLength)
            if (this.currentScale.length < 25 || !this.currentScale.length) {
              this.$q.notify({
                color: 'negative',
                textColor: 'white',
                icon: 'error',
                message: this.$t('app.invalid_scale'),
                position: 'top'
              })
              this.canvas.remove(this.currentScale)
              this.currentScale = null
            } else {
              this.showScaleDialog = true
              this.getScalePosition()

              this.canvas.remove(this.currentScale)
              this.canvas.add(this.currentScale)
            }
          }
          this.isDrawing = false
          break
        case 'distance':
          if (this.isDrawing) {
            this.showDistanceDialog = true
            this.getDistancePosition()
          }
          this.isDrawing = false
          break
      }
    },
    onObjectMoved (opt) {
      if (opt.target === this.currentScale) {
        this.getScalePosition()
      } else if (opt.target.type === 'polygon') {
        this.onObjectModified(opt)
      }
      this.debouncedSaveCanvas()
    },
    onObjectModified (opt) {
      const object = opt.target
      if (object.type === 'polygon') {
        object.updateInnerLabel()
      } else if (object['object-type'] === 'scale') {
        this.currentScale = object
        object.set('length', this.calculateLineLength(first(this.getObjects(object))))
        this.updateAllInnerLabels()
      }
      this.debouncedSaveCanvas()
    },
    onKeyDown (e) {
      e = e || window.event
      var key = e.which || e.keyCode
      var ctrl = e.ctrlKey ? e.ctrlKey : key === 17

      switch (key) {
        case 8:
        case 46:
          // del or canc
          var activeObjects = this.canvas.getActiveObjects()
          this.deleteObjects(activeObjects)
          break
        case 27:
          // esc
          this.cancelAction()
          break
        case 67:
          // ctrl + c
          if (ctrl) {
            this.copy()
          }
          break
        case 86:
          // ctrl + v
          if (ctrl) {
            this.paste()
          }
          break
      }
    },
    saveCanvas (page) {
      if (!this.loading && this.canvas) {
        const selectedPage = page || this.selectedPage
        selectedPage.data = this.canvas.toJSON(['percentage', 'object-type', 'value', 'length', 'width', 'height', 'objectCaching', 'innerLabel', 'scale', 'selectedLabelsMode'])

        if (selectedPage.data.backgroundImage) {
          // save viewport before saving image, needed to exclude zoom and pan when saving
          var transform = this.canvas.viewportTransform.slice()
          this.canvas.viewportTransform = [1, 0, 0, 1, 0, 0]
          // Temporary store the canvas size before saving
          const canvasSize = {
            width: this.canvas.getWidth(),
            height: this.canvas.getHeight()
          }
          // console.log(canvasSize)
          this.canvas.setWidth(selectedPage.data.backgroundImage.width)
          this.canvas.setHeight(selectedPage.data.backgroundImage.height)

          // Get the ratio
          // const ratioX = this.canvas.getWidth() / canvasSize.width
          let ratio = selectedPage.data.backgroundImage.height / canvasSize.height
          // If the width is greater than the height, then we need to scale the width down to match the height
          if (selectedPage.data.backgroundImage.width > selectedPage.data.backgroundImage.height) {
            ratio = selectedPage.data.backgroundImage.width / canvasSize.width
          }
          // Apply the ratio to the viewport transform
          this.canvas.setZoom(ratio)

          const bgImg = selectedPage.data.backgroundImage
          const cropWidth = bgImg.width
          const cropHeight = bgImg.height
          let cropX = bgImg.left * ratio - cropWidth / 2
          let cropY = 0
          if (selectedPage.data.backgroundImage.width > selectedPage.data.backgroundImage.height) {
            cropX = 0
            cropY = bgImg.top * ratio - cropHeight / 2
          }
          // const cropY = bgImg.top - (cropHeight / 2)

          selectedPage.canvas_image = this.canvas.toDataURL({ format: 'jpeg', left: cropX, top: cropY, width: cropWidth, height: cropHeight })
          this.canvas.viewportTransform = transform
          this.canvas.setWidth(canvasSize.width)
          this.canvas.setHeight(canvasSize.height)
          this.zoomToPoint({ x: canvasSize.width / 2, y: canvasSize.height / 2 }, transform[0], 'canvas-container')
        }
      }
    },
    initCanvas () {
      this.canvas = new fabric.Canvas('canvas-tools', {
        backgroundColor: '#eee',
        perPixelTargetFind: true,
        selection: this.action === 'select'
      })

      const canvasContainer = document.getElementById('canvas-container')
      this.canvas.setWidth(canvasContainer.offsetWidth)
      this.canvas.setHeight(canvasContainer.offsetHeight)

      if (this.selectedPage) {
        // this.drawImage(this.selectedPage.background_image)
        this.drawImage(this.selectedPage.background_image ? this.selectedPage.background_image : this.selectedPage.background_image_url)
      }
      this.initCanvasEvents()
      setTimeout(() => {
        this.setEdited(false)
      }, 100)
    },
    disposeCanvasEvents () {
      this.canvas.off('path:created', this.setEdited)

      this.canvas.off('object:added', this.setEdited)
      this.canvas.off('object:modified', this.onObjectModified)
      this.canvas.off('object:removed', this.setEdited)
      this.canvas.off('object:moving', this.onObjectMoved)
      this.canvas.off('object:moved', this.onObjectMoved)

      this.canvas.off('mouse:wheel', this.onMouseWheel)
      this.canvas.off('mouse:down', this.onMouseDown)
      this.canvas.off('mouse:move', this.onMouseMove)
      this.canvas.off('mouse:up', this.onMouseUp)
      this.canvas.off('mouse:dblclick', this.onDoubleClick)

      this.canvas.off('selection:created', this.onSelectionCreated)
      this.canvas.off('selection:cleared', this.onSelectionCleared)
      this.canvas.off('selection:updated', this.onSelectionUpdated)

      document.body.removeEventListener('keydown', this.onKeyDown)
    },
    initCanvasEvents () {
      this.canvas.on('path:created', this.setEdited)

      this.canvas.on('object:added', this.setEdited)
      this.canvas.on('object:modified', this.onObjectModified)
      this.canvas.on('object:removed', this.setEdited)
      this.canvas.on('object:moving', debounce(this.onObjectMoved, 10))
      this.canvas.on('object:moved', this.onObjectMoved)

      this.canvas.on('mouse:wheel', this.onMouseWheel)
      this.canvas.on('mouse:down', this.onMouseDown)
      this.canvas.on('mouse:move', this.onMouseMove)
      this.canvas.on('mouse:up', this.onMouseUp)
      this.canvas.on('mouse:dblclick', this.onDoubleClick)

      this.canvas.on('selection:created', this.onSelectionCreated)
      this.canvas.on('selection:cleared', this.onSelectionCleared)
      this.canvas.on('selection:updated', this.onSelectionUpdated)

      document.body.addEventListener('keydown', this.onKeyDown)
      document.getElementById('canvas-container').addEventListener('contextmenu', (evt) => {
        this.isDragging = true
        this.selection = false
        this.lastPos = {
          x: evt.clientX,
          y: evt.clientY
        }
        evt.preventDefault()
        return false
      })
      document.getElementById('canvas-container').addEventListener('mouseup', (evt) => {
        if (evt.which === 3) {
          this.onMouseUp(evt)
        }
      })
    },
    initFabric () {
      var deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"
      var deleteImg = document.createElement('img')
      const _self = this
      deleteImg.src = deleteIcon
      fabric.Object.NUM_FRACTION_DIGITS = 8
      fabric.Polygon.prototype.getPolygonCenter = function () {
        const pts = cloneDeep(this.points)
        var first = pts[0], last = pts[pts.length - 1]
        if (first.x !== last.x || first.y !== last.y) pts.push(first)
        let twicearea = 0, x = 0, y = 0, p1, p2, f
        const nPts = pts.length
        for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
          p1 = pts[i]; p2 = pts[j]
          f = p1.x * p2.y - p2.x * p1.y
          twicearea += f
          x += (p1.x + p2.x) * f
          y += (p1.y + p2.y) * f
        }
        f = twicearea * 3
        if (f === 0) return { x: 0, y: 0 }

        return { x: x / f, y: y / f }
      }
      fabric.Polygon.prototype.drawInnerLabel = function () {
        const center = this.getPolygonCenter()
        const absolutePoint = fabric.util.transformPoint({
          x: (center.x - this.pathOffset.x),
          y: (center.y - this.pathOffset.y)
        }, this.calcTransformMatrix())
        var polygonArea = area(this.points) * this.scaleX * this.scaleY
        const scale = find(this.canvas.getObjects(), { 'object-type': 'scale' })
        if (scale && scale.value) {
          const scaleLength = scale.length
          polygonArea *= (parseFloat(scale.value) / scaleLength) ** 2
        }

        _self.calculateArea()

        const bg = new fabric.Rect({
          fill: 'white',
          stroke: 'black',
          scaleY: 0.5,
          originX: 'center',
          originY: 'center',
          left: 0,
          rx: 5,
          ry: 5,
          width: 80,
          height: 60
        })

        const percentageBg = new fabric.Rect({
          fill: '#424242',
          scaleY: 0.5,
          originX: 'center',
          originY: 'center',
          left: 62,
          rx: 5,
          ry: 5,
          width: 40,
          height: 60
        })

        const text = new fabric.Text(polygonArea.toFixed(2) + ' m²', {
          originX: 'center',
          originY: 'center',
          left: 0,
          fontSize: 15
        })

        const percentage = new fabric.Text(Math.round((this.percentage)) + '%', {
          fill: 'white',
          originX: 'center',
          originY: 'center',
          left: 62,
          fontSize: 13
        })

        const group = new fabric.Group([], {
          originX: 'center',
          originY: 'center',
          hasControls: true,
          hasBorders: false
        })
        this.set('innerLabel', group)

        if (this.canvas.selectedLabelsMode !== 'hidden') {
          this.innerLabel.set('opacity', scale && scale.value ? 1 : 0)
          bg.set('opacity', (this.canvas.selectedLabelsMode === 'visible' || this.canvas.selectedLabelsMode === 'visible_small') ? 1 : 0.5)
          if (this.canvas.selectedPercentageMode === 'hidden') {
            percentageBg.set('opacity', 0)
            percentage.set('opacity', 0)
            bg.set('left', 42)
            text.set('left', 42)
          } else {
            percentage.set('opacity', 1)
            percentageBg.set('opacity', (this.canvas.selectedLabelsMode === 'visible' || this.canvas.selectedLabelsMode === 'visible_small') ? 1 : 0.5)
            bg.set('left', 0)
            text.set('left', 0)
          }

          if (this.canvas.selectedLabelsMode === 'transparent_text_only' || this.canvas.selectedLabelsMode === 'visible_small') {
            bg.set('width', 60 + (5 * polygonArea / 100))
            bg.set('height', 30)
            percentageBg.set('width', 40)
            percentageBg.set('height', 30)
            percentageBg.set('left', 51 + (2.5 * polygonArea / 100))
            percentage.set('left', 51 + (2.5 * polygonArea / 100))
          } else {
            bg.set('width', 80)
            bg.set('height', 60)
            percentageBg.set('width', 40)
            percentageBg.set('height', 60)
          }
        } else {
          this.innerLabel.set('opacity', 0)
        }

        this.innerLabel.addWithUpdate(percentageBg)
        this.innerLabel.addWithUpdate(percentage)
        this.innerLabel.addWithUpdate(bg)
        this.innerLabel.addWithUpdate(text)
        this.innerLabel.set('object-type', 'inner-label')
        this.innerLabel.set('left', absolutePoint.x)
        this.innerLabel.set('top', absolutePoint.y)

        this.canvas.add(group)
        this.canvas.requestRenderAll()
      }

      fabric.Polygon.prototype.updateInnerLabel = function () {
        // update inner label position
        const center = this.getPolygonCenter()
        const absoluteCenter = fabric.util.transformPoint({
          x: (center.x - this.pathOffset.x),
          y: (center.y - this.pathOffset.y)
        }, this.calcTransformMatrix())

        if (this.innerLabel) {
          this.innerLabel.left = absoluteCenter.x
          this.innerLabel.top = absoluteCenter.y
          var polygonArea = area(this.points) * this.scaleX * this.scaleY
          const scale = find(this.canvas.getObjects(), { 'object-type': 'scale' })
          if (scale && scale.value) {
            const scaleLength = scale.length
            polygonArea *= (parseFloat(scale.value) / scaleLength) ** 2
          }
          const text = filter(this.innerLabel.getObjects(), { type: 'text' })[1]
          const bg = filter(this.innerLabel.getObjects(), { type: 'rect' })[1]
          const percentage = filter(this.innerLabel.getObjects(), { type: 'text' })[0]
          const percentageBg = filter(this.innerLabel.getObjects(), { type: 'rect' })[0]
          _self.calculateArea()
          text.set('text', polygonArea.toFixed(2) + ' m²')
          percentage.set('text', Math.round(this.percentage) + '%')
          if (this.canvas.selectedLabelsMode !== 'hidden') {
            this.innerLabel.set('opacity', scale && scale.value ? 1 : 0)
            bg.set('opacity', (this.canvas.selectedLabelsMode === 'visible' || this.canvas.selectedLabelsMode === 'visible_small') ? 1 : 0.5)
            if (this.canvas.selectedPercentageMode === 'hidden') {
              percentageBg.set('opacity', 0)
              percentage.set('opacity', 0)
              bg.set('left', 0)
              text.set('left', 0)
            } else {
              percentage.set('opacity', 1)
              percentageBg.set('opacity', (this.canvas.selectedLabelsMode === 'visible' || this.canvas.selectedLabelsMode === 'visible_small') ? 1 : 0.5)
              bg.set('left', -20)
              text.set('left', -20)
            }
            if (this.canvas.selectedLabelsMode === 'transparent_text_only' || this.canvas.selectedLabelsMode === 'visible_small') {
              bg.set('width', 60 + (5 * polygonArea / 100))
              bg.set('height', 30)
              percentageBg.set('width', 40)
              percentageBg.set('height', 30)
              percentageBg.set('left', 31 + (2.5 * polygonArea / 100))
              percentage.set('left', 31 + (2.5 * polygonArea / 100))
            } else {
              bg.set('width', 80)
              bg.set('height', 60)
              percentageBg.set('width', 40)
              percentageBg.set('height', 60)
              percentageBg.set('left', 42)
              percentage.set('left', 42)
            }
          } else {
            this.innerLabel.set('opacity', 0)
          }
          this.canvas.requestRenderAll()
        }
      }

      fabric.Polygon.prototype.removeInnerLabel = function () {
        if (this.innerLabel) {
          this.innerLabel.getObjects().forEach(object => {
            this.innerLabel.removeWithUpdate(object)
          })
          this.innerLabel = null
        }
      }

      this.prototypeFabric.polygon = {
        getPolygonArea: (polygon) => {
          try {
            return area(polygon.points)
          } catch (e) {
            return 0
          }
        },
        checkIntersections: (pointer, lines = []) => {
          if (!lines.length) return false

          const lastPoint = {
            x: this.polygonVariables.pointArray[this.polygonVariables.pointArray.length - 1].left,
            y: this.polygonVariables.pointArray[this.polygonVariables.pointArray.length - 1].top
          }
          const myLine = new fabric.Line([lastPoint.x, lastPoint.y, pointer.x, pointer.y])

          let intersect = false
          each(lines, line => {
            if ((myLine.x1 !== line.x2 || myLine.y1 !== line.y2) && (myLine.x1 !== line.x1 || myLine.y1 !== line.y1)) {
              const intersection = fabric.Intersection.intersectLineLine(
                {
                  x: myLine.x1,
                  y: myLine.y1
                },
                {
                  x: myLine.x2,
                  y: myLine.y2
                },
                {
                  x: line.x1,
                  y: line.y1
                },
                {
                  x: line.x2,
                  y: line.y2
                }
              )
              intersect |= intersection.points.length
            }
          })
          return intersect
        },
        drawPolygon: () => {
          this.polygonVariables.polygonMode = true
          this.polygonVariables.pointArray = []
          this.polygonVariables.lineArray = []
          this.polygonVariables.activeLine = null
          this.polygonVariables.activeShape = null
        },
        addPoint: opt => {
          const pointer = this.canvas.getPointer()

          if (this.polygonVariables.pointArray.length) {
            if (this.prototypeFabric.polygon.checkIntersections(pointer, this.polygonVariables.lineArray)) {
              return
            }
          }
          this.prototypeFabric.polygon.addPointAtCoordinates(pointer.x, pointer.y)
        },
        addPointAtCoordinates: (x, y) => {
          const id = new Date().getTime()
          const circle = new fabric.Circle({
            radius: 1,
            fill: '#ffffff',
            stroke: '#333333',
            strokeWidth: 0.5,
            left: x,
            top: y,
            selectable: false,
            hasBorders: false,
            hasControls: false,
            originX: 'center',
            originY: 'center',
            id: id,
            objectCaching: false
          })
          if (this.polygonVariables.pointArray.length === 0) {
            circle.set({
              fill: this.selectedPercentage.bgColor
            })
          }
          let points = [x, y, x, y]
          const line = new fabric.Line(points, {
            strokeWidth: 1,
            fill: '#999999',
            stroke: '#999999',
            class: 'line',
            originX: 'center',
            originY: 'center',
            selectable: false,
            hasBorders: false,
            hasControls: false,
            evented: false,
            objectCaching: false
          })
          if (this.polygonVariables.activeShape) {
            points = this.polygonVariables.activeShape.get('points')
            points.push({ x, y })
            const polygon = new fabric.Polygon(points, {
              stroke: this.selectedPercentage.bgColor,
              // strokeDashArray: [5, 7],
              strokeWidth: 1,
              fill: this.selectedPercentage.bgColor + '33',
              opacity: 1,
              selectable: true,
              hasBorders: false,
              hasControls: false,
              evented: false,
              objectCaching: false,
              innerLabel: null
            })
            if (this.polygonVariables.activeShape.innerLabel) {
              this.canvas.remove(this.polygonVariables.activeShape.innerLabel)
            }
            this.canvas.remove(this.polygonVariables.activeShape)
            this.canvas.add(polygon)
            // polygon.drawInnerLabel()
            this.polygonVariables.activeShape = polygon
            this.canvas.requestRenderAll()
          } else {
            const polyPoint = [{ x, y }]
            const polygon = new fabric.Polygon(polyPoint, {
              stroke: this.selectedPercentage.bgColor,
              // strokeDashArray: [5, 7],
              strokeWidth: 1,
              fill: this.selectedPercentage.bgColor + '33',
              opacity: 1,
              selectable: true,
              hasBorders: false,
              hasControls: false,
              evented: false,
              objectCaching: false,
              innerLabel: null
            })
            this.canvas.add(polygon)
            this.polygonVariables.activeShape = polygon
          }
          this.polygonVariables.activeLine = line

          this.polygonVariables.pointArray.push(circle)
          this.polygonVariables.lineArray.push(line)

          // this.canvas.add(line)
          this.canvas.add(circle)
          this.canvas.selection = false
        },
        generatePolygon: pointArray => {
          // remove last point because of activeShape having one more point to show last line
          // const points = reverse(tail(this.polygonVariables.activeShape.get('points')))
          each(pointArray, point => {
            this.canvas.remove(point)
          })
          each(this.polygonVariables.lineArray, line => {
            this.canvas.remove(line)
          })
          if (this.polygonVariables.activeShape.innerLabel) {
            this.canvas.remove(this.polygonVariables.activeShape.innerLabel)
          }
          this.canvas.remove(this.polygonVariables.activeShape).remove(this.polygonVariables.activeLine)
          const polygon = new fabric.Polygon(pointArray.map(p => { return { x: p.left, y: p.top } }), {
            stroke: this.selectedPercentage.bgColor,
            // strokeDashArray: [5, 7],
            strokeWidth: 1,
            fill: this.selectedPercentage.bgColor + '80',
            opacity: 1,
            selectable: this.action !== 'drag',
            hasBorders: true,
            hasControls: true,
            objectCaching: false,
            percentage: this.selectedPercentage.value,
            innerLabel: null
          })
          this.canvas.add(polygon)
          polygon.drawInnerLabel()
          this.canvas.requestRenderAll()

          this.polygonVariables.activeLine = null
          this.polygonVariables.activeShape = null
          this.prototypeFabric.polygon.drawPolygon()
          if (this.action === 'polygon') {
            this.action = 'select'
          }
        },
        toggleEditPolygon: (e, poly) => {
          this.canvas.setActiveObject(poly)
          poly.edit = !poly.edit
          if (poly.edit) {
            var lastControl = poly.points.length - 1
            const editStyle = {
              cornerStyle: 'circle',
              cornerColor: 'rgba(0,0,255,0.5)'
            }
            poly.controls = {
              ...poly.controls,
              ...poly.points.reduce((acc, point, index) => {
                acc['p' + index] = new fabric.Control({
                  positionHandler: this.prototypeFabric.polygon.polygonPositionHandler,
                  actionHandler: this.prototypeFabric.polygon.anchorWrapper(index > 0 ? index - 1 : lastControl, this.prototypeFabric.polygon.actionHandler),
                  actionName: 'modifyPolygon',
                  pointIndex: index,
                  render: function (ctx, left, top, styleOverride, fabricObject) {
                    fabric.controlsUtils.renderCircleControl.call(this, ctx, left, top, editStyle, fabricObject)
                  }
                })
                return acc
              }, { })
            }
          } else {
            poly.cornerStyle = 'rect'
            poly.controls = fabric.Object.prototype.controls
          }
          // poly.hasBorders = !poly.edit
          this.canvas.requestRenderAll()
        },
        deleteObject: (e, target) => {
          this.deleteObjects(target.type === 'activeSelection' ? target._objects : [target])
        },
        polygonPositionHandler (dim, finalMatrix, fabricObject) {
          var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
            y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y)
          return fabric.util.transformPoint(
            { x: x, y: y },
            fabric.util.multiplyTransformMatrices(
              fabricObject.canvas.viewportTransform,
              fabricObject.calcTransformMatrix()
            )
          )
        },
        actionHandler (eventData, transform, x, y) {
          var polygon = transform.target,
            currentControl = polygon.controls[polygon.__corner],
            mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
            polygonBaseSize = polygon._getNonTransformedDimensions(),
            size = polygon._getTransformedDimensions(0, 0)
          polygon.points[currentControl.pointIndex] = {
            x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
            y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
          }
          polygon.updateInnerLabel()

          return true
        },
        anchorWrapper (anchorIndex, fn) {
          return function (eventData, transform, x, y) {
            var fabricObject = transform.target
            const absolutePoint = fabric.util.transformPoint({
              x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
              y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y)
            }, fabricObject.calcTransformMatrix())
            const actionPerformed = fn(eventData, transform, x, y)
            fabricObject._setPositionDimensions({})
            const polygonBaseSize = fabricObject._getNonTransformedDimensions()
            const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x
            const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y
            fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5)
            return actionPerformed
          }
        },
        resizePolygon () {
          let activeObject = this.canvas.getActiveObject()
          if (!activeObject) {
            activeObject = this.canvas.getObjects()[0]
            this.canvas.setActiveObject(activeObject)
          }

          activeObject.edit = false
          activeObject.objectCaching = false
          activeObject.controls = fabric.Object.prototype.controls
          activeObject.cornerStyle = 'rect'
          activeObject.hasBorders = true

          this.canvas.requestRenderAll()
        },
        renderDeleteIcon (ctx, left, top, styleOverride, fabricObject) {
          var size = this.cornerSize
          ctx.save()
          ctx.translate(left, top)
          ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
          ctx.drawImage(deleteImg, -size / 2, -size / 2, size, size)
          ctx.restore()
        }
      }
      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 16,
        offsetX: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: this.prototypeFabric.polygon.deleteObject,
        render: this.prototypeFabric.polygon.renderDeleteIcon,
        cornerSize: 24
      })
    },
    copy () {
      this.canvas.getActiveObject().clone(cloned => {
        this.clipboard = cloned
      }, ['percentage', 'object-type', 'value', 'length', 'width', 'height', 'objectCaching', 'innerLabel', 'scale', 'selectedLabelsMode'])
    },
    paste () {
      this.clipboard.clone(clonedObj => {
        this.canvas.discardActiveObject()
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true
        })
        if (clonedObj.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = this.canvas
          clonedObj.forEachObject(obj => {
            this.canvas.add(obj)
          })
          // this should solve the unselectability
          clonedObj.setCoords()
        } else {
          this.canvas.add(clonedObj)
        }
        this.clipboard.top += 10
        this.clipboard.left += 10
        this.canvas.setActiveObject(clonedObj)
        this.canvas.requestRenderAll()
      }, ['percentage', 'object-type', 'value', 'length', 'width', 'height', 'objectCaching', 'innerLabel', 'scale', 'selectedLabelsMode'])
    },
    deleteObjects (objects = []) {
      each(objects, object => {
        if (object['object-type'] === 'scale') {
          this.currentScale = null
        }
        if (object.innerLabel) {
          this.canvas.remove(object.innerLabel)
        }
        this.canvas.remove(object)
      })
      this.canvas.discardActiveObject().renderAll()
    },
    zoomIn () {
      var zoom = this.canvas.getZoom()
      const center = this.canvas.getCenter()
      zoom *= 1.1
      if (zoom > 8) zoom = 8
      this.zoomToPoint({ x: center.left, y: center.top }, zoom, 'canvas-container')
    },
    zoomOut () {
      var zoom = this.canvas.getZoom()
      const center = this.canvas.getCenter()
      zoom *= 0.9
      if (zoom < 0.3) zoom = 0.3
      this.zoomToPoint({ x: center.left, y: center.top }, zoom, 'canvas-container')
    },
    zoomToPoint (point, zoom, containerId) {
      this.canvas.zoomToPoint(point, zoom)

      // const container = document.getElementById(containerId)
      // if (container) {
      //   var vpt = this.canvas.viewportTransform
      //   if (vpt[4] >= 0) {
      //     vpt[4] = 0
      //   } else if (vpt[4] < this.canvas.getWidth() - container.offsetWidth * zoom) {
      //     vpt[4] = this.canvas.getWidth() - container.offsetWidth * zoom
      //   }
      //   if (vpt[5] >= 0) {
      //     vpt[5] = 0
      //   } else if (vpt[5] < this.canvas.getHeight() - container.offsetHeight * zoom) {
      //     vpt[5] = this.canvas.getHeight() - container.offsetHeight * zoom
      //   }
      //   this.canvas.requestRenderAll()
      // }
    },
    drawImage (image) {
      const img = new Image()
      img.crossOrigin = '*'
      img.onload = () => {
        const fImg = new fabric.Image(img)
        const center = this.canvas.getCenter()

        const canvasWidth = this.canvas.width
        const canvasHeight = this.canvas.height
        const canvasAspect = canvasWidth / canvasHeight
        const imgAspect = fImg.width / fImg.height

        let scaleFactor = 1

        if (canvasAspect <= imgAspect) {
          scaleFactor = canvasWidth / fImg.width
        } else {
          scaleFactor = canvasHeight / fImg.height
        }

        this.canvas.setBackgroundImage(fImg, this.canvas.requestRenderAll.bind(this.canvas), {
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          top: center.top,
          left: center.left,
          originX: 'center',
          originY: 'center'
        })
        this.canvas.requestRenderAll()
      }
      img.src = image
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.resetDataToInitialState()
    next()
  },
  created () {
    if (this.$route.params.id) {
      this.getProject(this.$route.params.id)
    } else {
      this.setEdited()
    }
  },
  mounted () {
    this.initFabric()
    this.debouncedSaveCanvas = debounce(this.saveCanvas, 500)
  }
}
</script>

<style lang="scss" scoped>
  .page-content {
    display: flex;
    flex-direction: column;
  }
</style>
