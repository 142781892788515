var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-dialog',{ref:"dialog",attrs:{"value":"","persistent":""},on:{"show":_vm.initData,"hide":_vm.onHide}},[_c('q-card',[_c('q-card-section',{staticClass:"text-center"},[_c('div',[_vm._v("Se desideri aggiungere alla stampa un valore al mq"),_c('br'),_vm._v(" o delle note compila questi campi")])]),_c('q-card-section',[_c('div',{staticClass:"row q-col-gutter-md q-mb-md"},[_c('div',{staticClass:"col column"},[_c('div',[_vm._v("Prezzo al mq")]),_c('q-input',{attrs:{"filled":"","min":"1","max":"100000","rules":[
                function (val) {
                  var regex = new RegExp('[0-9]+');
                  return regex.test(val)
                },
                function (val) { return val >= 0 || 'Il valore deve essere maggiore di 0'; },
                function (val) { return val <= 100000 || 'Il valore massimo consentito è 100000'; }
              ],"lazy-rules":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"euro","size":"xs"}})]},proxy:true}]),model:{value:(_vm.squareMeterPrice),callback:function ($$v) {_vm.squareMeterPrice=$$v},expression:"squareMeterPrice"}})],1),_c('div',{staticClass:"col column"},[_c('div',[_vm._v("Superficie")]),_c('q-input',{attrs:{"disable":"","filled":"","value":_vm.totalAreaInt},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('small',[_vm._v("mq")])]},proxy:true}])})],1),_c('div',{staticClass:"col column"},[_c('div',[_vm._v("Valore immobile")]),_c('q-input',{attrs:{"disable":"","filled":"","value":_vm.value},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"euro","size":"xs"}})]},proxy:true}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('q-input',{attrs:{"label":"Note","filled":"","hint":"Qui puoi inserire un commento sulla valutazione","type":"textarea"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)])]),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"outline":"","size":"md","color":"accent"}},[_vm._v(_vm._s(_vm.$t('generals.close')))]),_c('q-btn',{attrs:{"size":"md","color":"accent"},on:{"click":_vm.saveValue}},[_vm._v(_vm._s(_vm.$t('Salva e continua')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }